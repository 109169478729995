<template>
  <div>
    <a-button icon="left" class="gmb-40" @click="back">返回</a-button>
    <div class="gtc gfs-20 gfw-b gmb-40">选择验证方式</div>
    <div class="gbd gpv-30 gph-30 g-center" style="width:800px">
      <div class="g-flex g-justify-space-between g-align-center gbd-b gpb-20">
        <div class="g-flex">
          <div class="gh-80p gw-80p g-flex g-flex-c gmr-20" style="border:1px solid #1890ff;border-radius:50%">
            <i class="iconfont icon-phone" style="font-size:40px;color:#1890ff"></i>
          </div>
          <div class="g-flex g-flex-column g-justify-space-between gpv-10">
            <div class="gfs-18 gfw-b">通过手机验证</div>
            <div class="gc-aaa">如果你的绑定手机可用，则可通过绑定的手机发送验证码修改</div>
          </div>
        </div>
        <a-button type="primary" @click="$router.push('/personal/security/phoneUpdatePassword')">立即验证</a-button>
      </div>
      <div class="g-flex g-justify-space-between g-align-center gbd-b gpv-20">
        <div class="g-flex">
          <div class="gh-80p gw-80p g-flex g-flex-c gmr-20" style="border:1px solid #1890ff;border-radius:50%">
            <i class="iconfont icon-letter" style="font-size:40px;color:#1890ff"></i>
          </div>
          <div class="g-flex g-flex-column g-justify-space-between gpv-10">
            <div class="gfs-18 gfw-b">通过邮箱验证</div>
            <div class="gc-aaa">如果你的账号绑定邮箱，则可通过向绑定邮箱发送验证码修改</div>
          </div>
        </div>
        <a-button type="primary" @click="$router.push('/personal/security/emailUpdatePassword')">立即验证</a-button>
      </div>
      <div class="g-flex g-justify-space-between g-align-center gpt-20">
        <div class="g-flex">
          <div class="gh-80p gw-80p g-flex g-flex-c gmr-20" style="border:1px solid #1890ff;border-radius:50%">
            <i class="iconfont icon-problem" style="font-size:40px;color:#1890ff"></i>
          </div>
          <div class="g-flex g-flex-column g-justify-space-between gpv-10">
            <div class="gfs-18 gfw-b">以上方式都不能用</div>
            <div class="gc-aaa">如果您的原手机号，邮箱已经无法使用，请联系我们</div>
          </div>
        </div>
        <a-button type="link">联系客服</a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    back() {
      this.$router.back()
    },
  },
}
</script>
